// @ts-check
import { z } from 'zod';

/**
 * Specify your server-side environment variables schema here.
 * This way you can ensure the app isn't built with invalid env vars.
 */
export const serverSchema = z.object({
  DATABASE_URL: z.string().url(),
  NODE_ENV: z.enum(['development', 'test', 'production', 'sandbox']),
  NEXTAUTH_SECRET: z.string(),
  NEXTAUTH_URL: z.string().url().optional(),
  NEXTAUTH_EMAIL_HOST: z.string(),
  NEXTAUTH_EMAIL_PORT: z.string(),
  NEXTAUTH_EMAIL_AUTH_USER: z.string(),
  NEXTAUTH_EMAIL_AUTH_PASS: z.string(),
  NEXTAUTH_EMAIL_FROM: z.string(),
  QSTASH_CURRENT_SIGNING_KEY: z.string(),
  QSTASH_NEXT_SIGNING_KEY: z.string(),
  QSTASH_CALLBACK_BASE_URL: z.string().optional(),
  QSTASH_TOKEN: z.string(),
  ABLY_PRIVATE_KEY: z.string(),
  USER_CREATION_CC_TO: z.string().optional(),
  S3_AWS_ACCESS_KEY_ID: z.string(),
  S3_AWS_SECRET_ACCESS_KEY: z.string(),
  AWS_S3_REGION: z.string(),
  AWS_S3_BUCKET_NAME: z.string(),
  EMAIL_INBOUND_PARSE_DOMAIN: z.string().optional(),
  EMAIL_INBOUND_PARSE_POSTMARK_DOMAIN: z.string().optional(),
  OPENAI_API_KEY: z.string().optional(),
  VERCEL_ENV: z.string().optional(),
  IMPORTED_ORDER_CC_TO: z.string().optional(),
});

/**
 * Specify your client-side environment variables schema here.
 * This way you can ensure the app isn't built with invalid env vars.
 * To expose them to the client, prefix them with `NEXT_PUBLIC_`.
 */
export const clientSchema = z.object({
  NEXT_PUBLIC_AWS_S3_REGION: z.string(),
  NEXT_PUBLIC_AWS_S3_BUCKET_NAME: z.string(),
  NEXT_PUBLIC_DATADOG_APPLICATION_ID: z.string().optional(),
  NEXT_PUBLIC_DATADOG_CLIENT_TOKEN: z.string().optional(),
  NEXT_PUBLIC_DATADOG_SESSION_REPLAY_ENABLED: z.string().optional(),
  NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA: z.string().optional(),
  NEXT_PUBLIC_VERCEL_ENV: z.string().optional(),
});

/**
 * You can't destruct `process.env` as a regular object, so you have to do
 * it manually here. This is because Next.js evaluates this at build time,
 * and only used environment variables are included in the build.
 * @type {{ [k in keyof z.infer<typeof clientSchema>]: z.infer<typeof clientSchema>[k] | undefined }}
 */
export const clientEnv = {
  NEXT_PUBLIC_AWS_S3_REGION: process.env.NEXT_PUBLIC_AWS_S3_REGION,
  NEXT_PUBLIC_AWS_S3_BUCKET_NAME: process.env.NEXT_PUBLIC_AWS_S3_BUCKET_NAME,
  NEXT_PUBLIC_DATADOG_SESSION_REPLAY_ENABLED:
    process.env.NEXT_PUBLIC_DATADOG_SESSION_REPLAY_ENABLED,
  NEXT_PUBLIC_DATADOG_APPLICATION_ID:
    process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID,
  NEXT_PUBLIC_DATADOG_CLIENT_TOKEN:
    process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN,
  NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA:
    process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA,
  NEXT_PUBLIC_VERCEL_ENV: process.env.NEXT_PUBLIC_VERCEL_ENV,
};
